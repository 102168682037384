<div class="modal-header">
  <h4 class="modal-title pull-center">
    Do you want to confirm this offer?
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef1.hide()" [disabled]="loading">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <div class="offerDetailsWrapper">

    <div class="detailRow1">
      <div class="detailItem" *ngIf="offerData.offerId && offerMaxLimit">
        <h3 class="title">
          Available Spending Limit
        </h3>
        <p class="value">
          <!-- $20,000.00 -->
          {{offerMaxLimit | currency}}
        </p>
      </div>
      <div class="detailItem">
        <h3 class="title">
          Factor Rate
        </h3>
        <p class="value">
          {{offerFactor}}
        </p>
      </div>
      <div class="detailItem">
        <h3 class="title">
          Estimated Payback Period
        </h3>
        <p class="value">
          {{offerTerm}}
        </p>
      </div>
      <div class="detailItem">
        <h3 class="title">
          Estimated Daily Payment
        </h3>
        <p class="value">
          {{offerDailyPayment | currency}}
        </p>
      </div>
    </div>

    <div class="detailRow2">
      <div class="detailItem2">
        <h3 class="title">
          Payment Frequency
        </h3>
        <p class="value">
          {{collectionSchedule | titlecase}}
        </p>
      </div>
    </div>

  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default acceptBtn" (click)="openStatusModal(confirmStatusTemplate)"
    [disabled]="loading">
    <span>Accept Offer</span>
    <div *ngIf="loading">
      <app-loader></app-loader>
    </div>
  </button>
  <button type="button" class="btn btn-default cancelBtn" (click)="modalRef1.hide()" [disabled]="loading">Go
    Back</button>
</div>




<ng-template #confirmStatusTemplate>
  <div class="modal-header">

    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef2.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="../../../../assets/images/innerPages/icons/success.svg" alt="Success" class="icon" />
    <h3 class="title">
      Thank you!
    </h3>
    <p class="message">
      Your offer has been submitted
    </p>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modalRef2.hide()">Close</button>
  </div> -->
</ng-template>
