import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/core/authentication.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoginFormErrorModalComponent } from '../login-form-error-modal/login-form-error-modal.component';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/core/account.service';
@Component({
  selector: 'app-mfa-otplogin',
  templateUrl: './mfa-otplogin.component.html',
  styleUrls: ['./mfa-otplogin.component.css']
})
export class MFAOtpLoginComponent implements OnInit {
  loggedIn = false;
  failedLogin = false;
  modalRef: BsModalRef;
  otpLoginForm: FormGroup;
  verifyOtpForm: FormGroup;
  isFieldFocussed = false;
  fieldTextType: boolean;
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  constructor(private toastr: ToastrService, public activatedRoute: ActivatedRoute, private _location: Location, public authService: AuthenticationService, private router: Router, private fb: FormBuilder, private http: HttpClient, 
    private modalService: BsModalService,
    private accountService:AccountService
  ) {
    this.otpLoginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })

    this.verifyOtpForm = this.fb.group({
      binding_code: ['', Validators.required]
    })
    clearTimeout(this.t);
    clearTimeout(this.interval);
    this.t = this.startTimer();
  }

  get email() {
    return this.otpLoginForm.get('email')
  }

  get otp() {
    return this.verifyOtpForm.get('binding_code')
  }

  username = null;
  username_masked = null;
  binding_code = null;
  initialState = {
    title: '',
    message: ''
  }

  isOtpSent = false;
landingPageDescription;
  ngOnInit() {
    this.landingPageDescription = localStorage.getItem('LANDING_PAGE_DESCRIPTION') ||  'Revenued offers a FlexLine + Card built for all businesses';
    // this.authService.getConfigs();

    this.loggedIn = false;
    if (this.router.url.split('/')[2]) {
      this.username = decodeURIComponent(this.router.url.split('/')[2].trim());
      this.username_masked = decodeURIComponent(this.router.url.split('/')[2].trim()).replace(/^(.)(.*)(.@.*)$/,
        (_, a, b, c) => a + b.replace(/./g, '*') + c
      );
    }
    if (this.router.url.split('/')[3]) {
      if (this.router.url.split('/')[3] === 'true') {
        this.isOtpSent = true;
      }
    }
    this.authService.loginError.subscribe((res) => {
      this.loggedIn = false;
      if (res === true) {
         const initialState = {
          title: 'Login Error',
          message: 'Invalid authentication'
          // message: !window.localStorage.getItem("loginError") ? 'Email address incorrect' : window.localStorage.getItem("loginError")
        };
        this.openModal(initialState);
      }
    })
  }

  forgotPwdOnFocus() {
    this.isFieldFocussed = true;
  }

  forgotPwdOnFocusOut() {
    this.isFieldFocussed = false;
  }

  otpOnFocus() {
    this.isFieldFocussed = true;
  }

  otpOnFocusOut() {
    this.isFieldFocussed = false;
  }
  // validateEmail(email) {
  //   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
  //     return (true)
  //   }
  //   return (false)
  // }
  load = false;
  sendEmailOtp(form, is_resend = 'false') {
    if (this.load) {
      return;
    }
    this.load = true;
    let email = "";
    if (is_resend === 'true') {
      this.binding_code = null;
      email = this.username.toLowerCase().trim();
    } else {
      if (form.valid) {
        email = form.value.email.toLowerCase().trim();
      }
    }

    if (email) {
      this.authService.sendEmailOtp(email)
        .subscribe(() => {
          this.load = false;
          this.isOtpSent = true;
          this.initialState = {
            title: 'Success',
            message: "OTP sent to email address."
          };

          clearTimeout(this.t);
          clearTimeout(this.interval);
          this.t = this.startTimer();
        }, (err) => {
          this.load = false;
          this.initialState = {
            title: 'Error',
            message: "User not registered!"
          };
          this.openModal(this.initialState);
        })
    }
    else {
      this.load = false;

      // alert("Please enter your email to proceed");
      this.initialState = {
        title: 'Error',
        message: "Please enter valid email address to proceed."
      };
      this.openModal(this.initialState);
    }
  }

  verifyEmailOtp() {

    if (this.loggedIn) {
      return;
    }
    if (!this.loggedIn) {
      if (this.verifyOtpForm.valid) {
        this.loggedIn = true;
        this.verifyOtpForm.value.email = this.username;
        this.verifyOtpForm.value.mfa_token = window.localStorage.getItem("mfa_token");
        this.verifyOtpForm.value.oob_code = window.localStorage.getItem("oob_code");
        //tbd
        this.accountService.logoutUser().subscribe({
          next: () => {},
          error: () => {},
          complete:()=>{
            localStorage.clear();
          }
        })
        const params = this.activatedRoute.snapshot.queryParams;
        const redirectURL = params['redirectURL']
        this.authService.appLogin(this.verifyOtpForm, this.failedLogin, this.loggedIn, redirectURL, false, true);
      }
      else {
        this.loggedIn = false;
        const initialState = {
          title: 'Error',
          message: 'Invalid OTP'
        };
        this.openModal(initialState);
      }
    }
  }

  openModal(initialState) {
    if (this.modalRef) {
      this.modalRef.hide();
    } 
    
    this.modalRef = this.modalService.show(LoginFormErrorModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }


  t = null;
  timeLeft = 60;
  interval;
  startTimer() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 0;
      }
    }, 1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }
}
