<section id="main_footer" class="d-none d-md-block d-lg-block d-xl-block">
    <div class="container">
        <div class="row no-gutters">
            <div class="col-md-4">
                <ul class="list-unstyled text-white">
                    <li *ngIf="noAuth === 'false'">
                        <a routerLink="/dashboard/prepay-cal">Understand Your Pricing</a>
                    </li>
                    <li *ngIf="noAuth === 'false'">
                        <a routerLink="/dashboard/payment-adjustment" (click)="paymentAdjustmentActive()">Payment
                            Adjustment</a>
                    </li>
                    <li><a href="https://www.revenued.com/about/" target="_blank">About Revenued</a></li>
                    <li><a href="https://www.revenued.com/terms/" target="_blank">Terms of Service</a></li>
                    <li><a href="https://www.revenued.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
                    <li><a href="https://www.revenued.com/revenued-cardholder-agreement/" target="_blank">Card Holder
                            Agreement</a></li>
                    <li><a href="https://www.revenued.com/faq/" target="_blank">FAQs</a></li>
                    <li><a href="https://www.revenued.com/contact/" target="_blank">Contact Us</a></li>
                    <li><a href="https://www.revenued.com/site-map/" target="_blank">Site Map</a></li>
                </ul>
            </div>
            <div class="col-md-4">

            </div>
            <div class="col-md-4">
                <div class="text-right text-white">
                    Revenued
                    <p style="font-size: 14px;"><span class="phoneIcon"><i class="fas fa-phone"
                                aria-hidden="true"></i></span>1-844-218-4834 (M-F 8am - 7pm)</p>

                </div>
                <div class="text-right text-white social_icons mt-3">
                    <a href="https://www.facebook.com/revenuedcard" target="_blank" class="socialIconLink">
                        <!-- <i class="fab fa-facebook-f" aria-hidden="true"></i> -->
                        <img src="https://2490387.fs1.hubspotusercontent-na1.net/hubfs/2490387/Facebook_Icon_Logo_30x30.png"
                            alt="Facebook" width="30" height="30" class="socialIcon">
                    </a>
                    <a href="https://twitter.com/revenuedcard" target="_blank" class="socialIconLink">
                        <!-- <i class="fab fa-twitter"></i> -->
                        <img src="https://2490387.fs1.hubspotusercontent-na1.net/hubfs/2490387/Twitter_Icon_Logo_30x30.png"
                            alt="Twitter" width="30" height="30" class="socialIcon">
                    </a>
                    <a href="https://www.linkedin.com/company/revenued/" target="_blank" class="socialIconLink">
                        <!-- <i class="fab fa-linkedin-in"></i> -->
                        <img src="https://2490387.fs1.hubspotusercontent-na1.net/hubfs/2490387/LinkedIn_Icon_Logo_30x30.png"
                            alt="LinkedIn" width="30" height="30" class="socialIcon">
                    </a>
                    <a href="https://www.instagram.com/revenuedcard/" target="_blank" class="socialIconLink"
                        data-wpel-link="external" rel="external noopener noreferrer">
                        <!-- <i class="fab fa-instagram"></i> -->
                        <img src="https://2490387.fs1.hubspotusercontent-na1.net/hubfs/2490387/Instagram_Icon_Logo_30x30.png"
                            alt="Instagram" width="30" height="30" class="socialIcon">
                    </a>
                </div>
                <div class="text-right text-white mt-3 mouse-pointerz">

                    <span *ngIf="platform"> {{platform}} <span
                            *ngIf="platformAppVersionNumber">v{{platformAppVersionNumber}}</span></span>

                </div>
            </div>
        </div>
        <div class="row no-gutters copy">
            <div class="col-md-12">
                <a href="https://www.revenued.com/about/" target="_blank">
                    <p>&copy;{{currentYear}} Revenued | All Rights Reserved.</p>
                </a>
            </div>
        </div>
    </div>
    <img src="/assets/images/bgSVG/footerCurve.svg" class="img-fluid footerSvg" alt="Footer Curve" />
</section>

<section class="versionNumber d-md-none d-lg-none d-xl-none" *ngIf="platform" [hidden]="hideFlag()">
    <p *ngIf="platform">
        {{platform}}
        <span *ngIf="platformAppVersionNumber">
            v{{platformAppVersionNumber}}
        </span>
    </p>
</section>